<template>
  <div class="hebing">
    <van-nav-bar
      title="账号列表"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="main">
      <van-cell v-for="(item, index) in list" :key="index">
        <template #title>
          <van-image
            :src="item.avatar"
            width="40"
            height="40"
            round
            lazy-load
            fill="cover"
          >
            <template v-slot:loading>
              <van-loading type="spinner" size="20" />
            </template>
          </van-image>
        </template>
        <template #default>
          <div class="van-ellipsis" style="font-size: 12px;">{{item.nickname}}</div>
          <div>
            <van-button @click="copy(item.agent_code)" size="mini" color="linear-gradient(to right, #ff6034, #ee0a24)" style="height: 18px;margin-right: 10px;">{{item.agent_code}}</van-button>
            <van-tag plain color="#FF6C37" text-color="#FF6C37" v-if="item.id == memberid">当前登陆中</van-tag>
          </div>
          <div>
            <van-tag plain type="danger">邀请人数量：{{item.next_member_num}}</van-tag>
            <van-tag plain type="danger">未完成订单：{{item.order_num}}</van-tag>
          </div>
        </template>
        <template #right-icon>
          <div>
            <div>
              <van-button @click="loginAccount(index)" size="mini" type="warning" :disabled="item.id == memberid">登陆</van-button>
            </div>
            <div style="margin-top: 10px;">
              <van-button @click="delAccount(index)" size="mini" :disabled="item.id == memberid">删除</van-button>
            </div>
          </div>
        </template>
      </van-cell>
    </div>
  </div>
</template>
<script>
import { Dialog } from 'vant';
export default {
  name: "Hebing",
  data() {
    return {
      merchid: 0,
      shareid: 0,
      list: [],
      memberid: 0,
    }
  },
  mounted() {
    if (this.$route.query.shareid) {
      this.shareid = this.$route.query.shareid
    }else if (localStorage.getItem("DK_MEMBERID")) {
      this.shareid = localStorage.getItem("DK_MEMBERID");
    }
    this.merchid = this.$route.query.merchid ? this.$route.query.merchid : 0;
    this.$parent.getmerchname(this.merchid, "账号列表");
    this.memberid = localStorage.getItem("DK_MEMBERID") ? localStorage.getItem("DK_MEMBERID") : 0;
    this.init();
  },
  methods: {
    init() {
      this.getlist();
    },
    getlist() {
      let openid = '';
      let wxinfo = '';
      openid = localStorage.getItem("DK_OPENID") ? localStorage.getItem("DK_OPENID") : '';
      wxinfo = localStorage.getItem("DK_WXINFO") ? localStorage.getItem("DK_WXINFO") : '';
      let that = null;
      that = this;
      that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("DK_UID") ? localStorage.getItem("DK_UID") : "";
      that.$axios
        .post(
          that.$store.state.domain + 'web/user/get_account_list',
          that.$qs.stringify({
            openid: openid,
            wxinfo: wxinfo
          })
        )
        .then(res => {
          console.log(res);
          if (res.data.code == 100000) {
            that.list = res.data.data;
          }
        })
        .catch(err => {
          console.log(err);
        })
    },
    // 登陆账号
    loginAccount(index) {
      let info = {};
      info = this.list[index];
      let uid = '';
      uid = info.uid;
      let msg = '';
      msg = '确认要登陆邀请码为：'+info.agent_code+' 的账号？';
      Dialog.confirm({
        title: '切换登陆账号',
        message: msg,
      })
        .then(() => {
          // on confirm
          localStorage.setItem("DK_TOKEN",info.pwd);
          localStorage.setItem("DK_SHAREID",info.id);
          localStorage.setItem("DK_MEMBERID",info.id);
          localStorage.setItem("DK_UID",uid);
          this.$toast("切换成功");
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        })
        .catch(() => {
          // on cancel
        });
    },
    // 删除账号
    delAccount(index) {
      let that = null;
      that = this;
      let info = {};
      info = this.list[index];
      let msg = '';
      msg = "确认要删除邀请码为："+info.agent_code+" 的账号？"
      Dialog.confirm({
        title: '删除账号',
        message: msg,
      })
        .then(() => {
          that.dodel(info);
        })
        .catch(() => {
          // on cancel
        });
    },
    dodel(info) {
      let that = null;
      that = this;
      let openid = '';
      openid = localStorage.getItem("DK_OPENID") ? localStorage.getItem("DK_OPENID") : '';

      that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("DK_UID") ? localStorage.getItem("DK_UID") : "";
      that.$axios
        .post(
          that.$store.state.domain + 'web/user/set_account',
          that.$qs.stringify({
            openid: openid,
            info: info
          })
        )
        .then(res => {
          console.log(res);
          if (res.data.code == 100000) {
            that.list = res.data.data;
          }
        })
        .catch(err => {
          console.log(err);
        })
    },
    // 复制邀请码
    copy(txt) {
      let _that = null;
      _that = this;
      _that.$copyText(txt).then(
        function(e) {
          _that.$toast("复制成功");
          console.log(e);
        },
        function(e) {
          console.log(e);
        }
      );
    },
    onClickLeft() {
      this.$router.back();
    },
  },
}
</script>
<style lang="less">
.hebing {
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  background-color: #fafafa;
  .van-nav-bar .van-icon {
    color: #333;
  }
  .main {
    .van-cell__title {
      margin-right: 10px;
    }
    .van-cell__value {
      flex: 1;
      text-align: left;
      color: #333;
      .van-tag {
        margin-right: 10px;
        &:last-of-type {
          margin-right: 0;
        }
      }
    }
  }
}
</style>